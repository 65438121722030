import { Icons } from '#/components/icons';
import { ROUTES } from './constants';

export interface MenuItem {
  imgURL?: any;
  imgURLActive?: any;
  href: string;
  label: string;
  active: boolean;
  children?: MenuItem[];
  popover?: boolean;
  isMenu?: boolean;
}

export const MenuItems = (pathname: string): MenuItem[] => [
  {
    imgURL: Icons.inactiveBook,
    imgURLActive: Icons.bookTextActive,
    href: ROUTES.HOME,
    label: '낭독',
    active: pathname.includes(ROUTES.HOME),
    children: HOME_ROUTES(pathname)
  },
  {
    imgURL: Icons.search,
    imgURLActive: Icons.searchActive,
    href: ROUTES.SEARCH,
    label: '검색',
    active: pathname.includes(ROUTES.SEARCH)
  },
  {
    imgURL: Icons.my,
    imgURLActive: Icons.myActive,
    href: ROUTES.MY,
    label: 'MY',
    active: pathname.includes(ROUTES.MY)
  },
  {
    imgURL: Icons.list,
    imgURLActive: Icons.listActive,
    href: ROUTES.MORE,
    label: '더보기',
    active: pathname.includes(ROUTES.MORE),
    children: MORE_ROUTES(pathname)
  }
];

export const HOME_ROUTES = (pathname: string) => [
  {
    href: ROUTES.FAMILY_MANAGE,
    label: '가족 관리',
    active: pathname.includes(ROUTES.FAMILY_MANAGE)
  },
  {
    href: ROUTES.FAMILY_MANAGE_ADD,
    label: '가족 연결하기',
    active: pathname.includes(ROUTES.FAMILY_MANAGE_ADD)
  },
  {
    href: ROUTES.FAMILY_MANAGE_ADD_CONFIRM,
    label: '가족 초대 확인',
    active: pathname.includes(ROUTES.FAMILY_MANAGE_ADD_CONFIRM)
  },
  {
    href: ROUTES.INVITE,
    label: '초대 수락하기',
    active: pathname.includes(ROUTES.INVITE)
  },
  {
    href: ROUTES.FAMILY_MISSION,
    label: '우리가족 활동미션',
    active: pathname.includes(ROUTES.FAMILY_MISSION)
  },
  {
    href: ROUTES.FAMILY_LOG,
    label: '우리가족 낭독자료',
    active: pathname.includes(ROUTES.FAMILY_LOG)
  },
  {
    href: ROUTES.FAMILY_STATISTICS,
    label: '활동 통계',
    active: pathname.includes(ROUTES.FAMILY_STATISTICS)
  }
];

export const RECORDER_ROUTES = (pathname: string) => [
  {
    href: ROUTES.RECORDER,
    label: '내 책 낭독하기',
    active: pathname.includes(ROUTES.RECORDER),
    isMenu: true
  },
  {
    href: ROUTES.DECLAMATION_LIFE_QUOTES,
    label: '낭독 100선',
    active: pathname.includes(ROUTES.DECLAMATION_LIFE_QUOTES),
    isMenu: true
  },
  {
    href: ROUTES.DECLAMATION_RESULT,
    label: '낭독 결과',
    active: pathname.includes(ROUTES.DECLAMATION_RESULT)
  }
];

export const MORE_SERVICE_ROUTES = (pathname: string) => [
  {
    imgURL: Icons.readingMenu,
    href: ROUTES.READDINGEFFECT,
    label: '낭독 예찬',
    active: pathname.includes(ROUTES.READDINGEFFECT)
  },
  {
    imgURL: Icons.noticeMenu,
    href: ROUTES.NOTICE,
    label: '공지사항',
    active: pathname.includes(ROUTES.NOTICE)
  }
  // {
  //   imgURL: Icons.notificationMenu,
  //   href: ROUTES.NOTIFICATIONSETTINGS,
  //   label: '알림',
  //   active: pathname.includes(ROUTES.NOTIFICATIONSETTINGS)
  // }
];

export const MORE_SETTING_ROUTES = (pathname: string) => [
  {
    imgURL: Icons.userMenu,
    href: ROUTES.MANAGE_USER,
    label: '계정 관리',
    active: pathname.includes(ROUTES.MANAGE_USER),
    children: USER_INFO_ROUTES(pathname)
  },
  {
    imgURL: Icons.settingMenu,
    href: ROUTES.NOTIFICATIONSETTINGS,
    label: '서비스 이용 설정',
    active: pathname.includes(ROUTES.NOTIFICATIONSETTINGS)
  },
  {
    imgURL: Icons.appVersionMenu,
    href: ROUTES.APP_VERSION,
    label: '앱 버전',
    active: pathname.includes(ROUTES.APP_VERSION)
  }
];

export const HELP_ROUTES = (pathname: string) => [
  {
    imgURL: Icons.faqMenu,
    href: ROUTES.FAQ,
    label: '자주 묻는 질문',
    active: pathname.includes(ROUTES.FAQ)
  },
  {
    imgURL: Icons.termsMenu,
    href: ROUTES.TERMS,
    label: '약관 확인하기',
    active: pathname.includes(ROUTES.TERMS)
  },
  {
    imgURL: Icons.helpMenu,
    href: ROUTES.CUSTOMER_SERVICE,
    label: '고객센터',
    active: pathname.includes(ROUTES.CUSTOMER_SERVICE)
  }
];

export const MORE_ROUTES = (pathname: string) => [
  ...MORE_SERVICE_ROUTES(pathname),
  ...MORE_SETTING_ROUTES(pathname),
  ...HELP_ROUTES(pathname)
];

export const USER_INFO_ROUTES = (pathname: string) => [
  {
    href: ROUTES.SOCIAL,
    label: '소셜연동',
    active: pathname.includes(ROUTES.SOCIAL)
  }
  //   {
  //     href: ROUTES.ADDRESS,
  //     label: '주소지 관리',
  //     active: pathname.includes(ROUTES.SOCIAL)
  //   }
];

function convertMenuText(p: Map<string, string>, l: MenuItem) {
  p.set(l.href, l.label);
  l.children?.forEach((item) => {
    convertMenuText(p, item);
  });
  return p;
}

export const MENU_TEXT_MAP = MenuItems('').reduce(convertMenuText, new Map());
