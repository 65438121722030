export const LOG_EVENTS = {
  HOME_SEARCH_BUTTON: 'home_search_button',
  MY_ACTIVITY: 'my_activity',
  INTRO_MORE: 'intro_more',
  WORD_PLAY_MORE: 'word_play_more',
  INTIMATE_MORE: 'intimate_more',
  ARTICLE_PACKAGE_THUMBNAIL: 'article_package_thumbnail',
  MINI_PLAYER_REPLAY: 'mini_player_replay',
  ARTICLE_TAB: 'article_tab',
  INFORMATION_TAB: 'information_tab',
  ARTICLE_IN_PACKAGE: 'article_in_package',
  READING_BUTTON: 'reading_button',
  READING_PLAY: 'reading_play',
  READING_PAUSE: 'reading_pause',
  READING_REPLAY: 'reading_replay',
  READING_RESET: 'reading_reset',
  READING_FINISH: 'reading_finish',
  CHUNKS_OPTION: 'chunks_option',
  WORD_OPTION: 'word_option',
  ORIGIN_OPTION: 'origin_option',
  READING_INTRODUCE: 'reading_introduce',
  BG_SETTINGS: 'bg_settings',
  SEARCH_SEARCH_BUTTON: 'search_search_button',
  TAG_SEARCH: 'tag_search',
  CATEGORY_SEARCH: 'category_search',
  AUTOCOMPLETE_SEARCH: 'autocomplete_search',
  SEARCH_QUERY: 'search_query',
  CATEGORY_SEARCH_FROM_RESULT: 'category_search_from_result',
  DELETE_QUERY: 'delete_query',
  READING_TIME_SCORE: 'reading_time_score',
  RECENT: 'recent',
  ATTENDANCE: 'attendance',
  RECENT_LIST: 'recent_list',
  TROPHY: 'trophy',
  RECENT_MORE: 'recnet_more',
  RECENT_ARTICLE_TAB: 'recent_article_tab',
  RECENT_ARTICLE_PACKAGE_TAB: 'recent_article_package_tab',
  RECENT_ARTICLE: 'recent_article',
  RECENT_ARTICLE_PACKAGE: 'recent_article_package',
  RECENT_REPLAY: 'recent_replay',
  WISH_MORE: 'wish_more',
  ADD_WISH: 'add_wish',
  REMOVE_WISH: 'remove_wish',
  NOT_FOUND_ARTICLE: 'not_found_article',
  NOT_FOUND_ARTICLE_PACKAGE: 'not_found_article_package',
  NOT_FOUND_WISH: 'not_found_wish',
  MAIN_BOTTOM: 'main_bottom',
  READING_EFFECT: 'reading_effect',
  NOTICE: 'notice',
  NOTICE_ITEM: 'notice_item',
  MANAGE_USER: 'manage_user',
  USER_INFO: 'user_info',
  WITHDRAWAL: 'withdrawal',
  WITHDRAWAL_YES: 'withdrawal_yes',
  WITHDRAWAL_NO: 'withdrawal_no',
  LOGOUT: 'logout',
  NOTIFICATION_SETTINGS: 'notification_settings',
  MARKETING_AGREEMENT: 'marketing_agreement',
  MARKETING_AGREEMENT_WITHDRAWAL_YES: 'marketing_agreement_withdrawal_yes',
  MARKETING_AGREEMENT_WITHDRAWAL_NO: 'marketing_agreement_withdrawal_no',
  APP_VERSION: 'app_version',
  FAQ: 'faq',
  FAQ_ITEM: 'faq_item',
  TERMS: 'terms',
  TERMS_ITEM: 'terms_item',
  CUSTOMER_SERVICE: 'customer_service',
  FAQ_BUTTON: 'faq_button',
  CUSTOMER_SERVICE_ADD: 'customer_service_add',
  CUSTOMER_SERVICE_ACCEPTANCE: 'customer_service_acceptance',
  MY_RECENT_DATA_FETCHING_ERROR: 'my_recent_data_fetching_error',
  STOP_RECORDING_ERROR: 'stop_recording_error',
  STT_ERROR: 'stt_error',
  FETCH_ARTICLE_PACKAGE_ERROR: 'fetch_article_package_error',
  MOBILE_LOGIN: 'mobile_login',
  SEND_SMS: 'send_sms',
  CONFIRM_SMS: 'confirm_sms',
  SIGN_IN: 'sign_in',
  NEW_USER: 'new_user',
  CHECK_TERMS_AGREEMENT: 'check_terms_agreement',
  TERMS_AGREEMENT_MORE_ITEM: 'terms_agreement_more_item',
  COMPLETE_NEW_SIGN_IN: 'complete_new_sign_in'
};

export const BG_COLORS = {
  WHITE: 'white',
  NEUTRAL: 'neutral',
  GRAY: 'gray',
  DARK_GRAY: 'dark_gray',
  BLACK: 'black'
};
