import { type ErrorResponse } from '#/lib/server-util';
import { TOAST } from '#/provider/toast-provider';
import { useRouter } from 'next/navigation';
import { UseFormReturn } from 'react-hook-form';

export { useFetch };

function useFetch() {
  const router = useRouter();

  return {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    patch: request('PATCH'),
    delete: request('DELETE'),
    upload: request('POST', true)
  };

  function request(method: string, formData?: boolean) {
    return (
      url: string,
      body?: any,
      form?: UseFormReturn<any>,
      timeout: number = 300000
    ) => {
      const requestOptions: any = {
        method,
        timeout
      };

      if (body) {
        if (formData) {
          const data = new FormData();
          Object.keys(body).forEach((k) => {
            data.set(k, body[k]);
          });
          requestOptions.body = data;
        } else {
          requestOptions.headers = { 'Content-Type': 'application/json' };
          requestOptions.body = JSON.stringify(body);
        }
      }
      return fetch(url, requestOptions).then((res) =>
        handleResponse(res, form)
      );
    };
  }

  // helper functions

  async function handleResponse(response: Response, form?: UseFormReturn<any>) {
    const isJson = response.headers
      ?.get('content-type')
      ?.includes('application/json');
    const data = isJson ? await response.json() : null;

    // check for error response
    let checked = false;
    if (!response.ok) {
      if (response.status === 401) {
        // api auto logs out on 401 Unauthorized, so redirect to login page
        router.push('/login');
      } else {
        const body = data as ErrorResponse;
        if (response.status === 409) {
          if (body.errors) {
            body.errors.forEach((name) => {
              form?.setError(name, { message: '이미 추가된 정보입니다.' });
            });
            TOAST.error('입력 값을 확인하세요.');
          } else {
            TOAST.error('이미 추가된 정보입니다.');
          }
          checked = true;
        } else if (response.status === 400) {
          body.errors.forEach((e) => {
            TOAST.error(
              `유효성 검사에 실패했습니다. [${e.path.join(', ')}: ${
                e.message
              }] `
            );
          });
          checked = true;
        } else {
          console.log(response);
        }
      }

      // get error message from body or default to response status
      const error = {
        checked,
        response,
        data
      };
      return Promise.reject(error);
    }

    return data;
  }
}

// @ts-ignore
BigInt.prototype.toJSON = function (): string {
  return this.toString();
};
