import { useRouter } from 'next/navigation';
import { useFetch } from '#/hooks/use-fetch';
import { ROUTES } from '#/data/constants';
import { signIn, signOut } from 'next-auth/react';
import { TOAST } from '#/provider/toast-provider';

export function useUserService(): AdminService {
  const client = useFetch();
  const router = useRouter();

  return {
    loginSms: async (mobile, code) => {
      return await signIn('credentials', {
        redirect: false,
        mobile,
        code
      });
    },
    register: async (email, password) => {
      try {
        await client.post(ROUTES.REGISTER_API, { email, password });
        TOAST.success('관리자 가입이 완료되었습니다.');
        router.push(ROUTES.ROOT);
      } catch ({ touched, response }: any) {
        if (!touched) {
          if (response.status === 404) {
            TOAST.error('추가되지 않은 사용자 입니다.');
          } else {
            TOAST.error('관리자 가입 중 오류가 발생하였습니다.');
          }
        }
      }
    },
    loginNaver: (callbackUrl) => {
      signIn('naver', { callbackUrl });
    },
    loginKakao: (callbackUrl) => {
      signIn('kakao', { callbackUrl });
    },
    loginApple: (callbackUrl) => {
      signIn('apple', { callbackUrl });
    },
    logout: async ({ callbackUrl, redirect = true }) => {
      await signOut({
        callbackUrl: callbackUrl || ROUTES.ROOT,
        redirect: redirect
      });
    }
  };
}

interface AdminService {
  register: (mobile: string, code: string) => Promise<any>;
  loginSms: (mobile: string, code: string) => Promise<any>;
  loginNaver: (callbackUrl?: string) => void;
  loginKakao: (callbackUrl?: string) => void;
  loginApple: (callbackUrl?: string) => void;
  logout: ({
    callbackUrl,
    redirect
  }: {
    callbackUrl?: string;
    redirect?: boolean;
  }) => Promise<void>;
}
