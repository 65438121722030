import { ReactElement } from 'react';

import { IconType } from '#/components/icons';
import Button from './button';

interface SocialButtonProps {
  loading?: boolean;
  disabled?: boolean;
  onClick: () => void;
  text: string;
  icon?: (cn: string) => ReactElement<IconType>;
  className?: string;
}

export default function SocialButton({
  loading,
  disabled,
  onClick,
  text,
  icon,
  className
}: SocialButtonProps) {
  return (
    <Button
      fullWidth
      isDisabled={disabled}
      isLoading={loading}
      onClick={onClick}
      startContent={icon && icon('mr-2 h-6 w-6')}
      className={className}
    >
      {text}
    </Button>
  );
}
