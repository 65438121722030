'use client';

import toast, { Toaster } from 'react-hot-toast';

export const ToastProvider = () => {
  return <Toaster />;
};

export const TOAST = {
  success: (text: string) => toast.success(text),
  error: (text: string) => toast.error(text),
  dissmiss: () => toast.dismiss()
};
