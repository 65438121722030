'use client';

import { useUserService } from '#/actions/client';
import SocialButton from '#/components/button/social-button';
import { Icons } from '#/components/icons';
import { ROUTES } from '#/data/constants';
import { getDenyBackRoute } from '#/data/deny-back';
import { LOG_EVENTS } from '#/data/event';
import { isIOS, OAUTH_URLS, setExceptPopupUrl } from '#/lib/webview';
import { logEvent } from '#/lib/analytics';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

export default function StartButton() {
  const router = useRouter();
  const service = useUserService();

  useEffect(() => {
    const handlePopState = async () => {
      history.pushState(null, document.title, location.href);
    };
    try {
      setExceptPopupUrl(OAUTH_URLS);
      history.pushState(null, document.title, location.href);
      window.addEventListener('popstate', handlePopState);
    } catch (error) {}
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <>
      <div className="space-y-2 mb-4">
        {/* <SocialButton
          text="네이버로 시작하기"
          icon={(className) => <Icons.naver className={className} />}
          onClick={() => {
            service.loginNaver(getDenyBackRoute(ROUTES.ROOT));
          }}
        />
        <SocialButton
          text="카카오로 시작하기"
          icon={(className) => (
            <Icons.kakao className={className + ' h-8 w-8'} />
          )}
          onClick={() => {
            service.loginKakao(getDenyBackRoute(ROUTES.ROOT));
          }}
          className="text-black bg-[#fee500]"
        />
        */}

        <SocialButton
          text="모바일로 시작하기"
          icon={(className) => <Icons.smartPhone className={className} />}
          onClick={() => {
            logEvent(LOG_EVENTS.MOBILE_LOGIN);
            router.push(ROUTES.SIGNIN, { scroll: false });
          }}
        />

        {isIOS() && (
          <>
            <div className="relative py-1">
              <div className="absolute inset-0 flex items-center">
                <span className="w-full border-t" />
              </div>
              <div className="relative flex justify-center text-xs uppercase">
                <span className="bg-background px-2 text-muted-foreground">
                  OR
                </span>
              </div>
            </div>

            <SocialButton
              text="Apple로 시작하기"
              icon={(className) => <Icons.apple className={className} />}
              onClick={() => {
                service.loginApple(getDenyBackRoute(ROUTES.ROOT));
              }}
              className="bg-black"
            />
          </>
        )}
      </div>
    </>
  );
}
