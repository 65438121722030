'use client';

import { useRouter } from 'next/navigation';
import Button from '../button/button';
import { Icons } from '../icons';
import { Progress } from '@nextui-org/react';
import { ReactNode } from 'react';

export interface AppBarProps {
  title?: string | ReactNode;
  hasBack?: boolean;
  children?: React.ReactNode;
  startContent?: React.ReactNode;
  backUrl?: string;
  actions?: React.ReactNode;
  onBack?: () => void;
  progress?: number;
  scroll?: React.ReactNode;
}

const AppBar = ({
  title,
  children,
  startContent,
  backUrl,
  actions,
  onBack,
  hasBack = true,
  progress,
  scroll
}: AppBarProps) => {
  const router = useRouter();
  return (
    <header
      id="appBar"
      className="sticky top-0 z-50 w-full border-b border-border-1 bg-background "
    >
      <div className="flex items-center justify-between appbar-height">
        <div className="flex items-center px-2">
          {hasBack && (
            <Button
              isIconOnly
              variant="light"
              color="default"
              onClick={() => {
                if (onBack) {
                  onBack();
                } else if (backUrl) {
                  router.push(backUrl);
                } else {
                  router.back();
                }
              }}
            >
              <Icons.chevronLeft className="w-6 h-6" />
            </Button>
          )}
          {startContent}
          {title && <h3 className="text-lg font-semibold">{title}</h3>}
          <div className="ml-auto flex items-center space-x-4">{children}</div>
        </div>
        {actions}
      </div>
      {progress && progress > 0 ? (
        <Progress
          size="sm"
          radius="none"
          aria-label="Loading..."
          value={progress}
        />
      ) : (
        progress === 0 && (
          <Progress size="sm" radius="none" aria-label="Loading..." value={0} />
        )
      )}
      {scroll && <div>{scroll}</div>}
    </header>
  );
};

export default AppBar;
